import React, { StrictMode } from "react";
import { CssBaseline } from "@mui/material";
import "./assets/logo/logosociais.jpg";
import getFirebaseApp from "./firebaseApp";

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  const firebaseAppInstance = getFirebaseApp();
  return (
    <main style={{ margin: 0 }} id="root">
      <StrictMode>
        <CssBaseline />
        {children}
      </StrictMode>
    </main>
  );
}
