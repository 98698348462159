import momentTz from "moment-timezone";
import moment from "moment";
import "moment/locale/pt-br";
import { initializeApp } from "firebase/app";
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";
import {
  getAuth,
  setPersistence,
  indexedDBLocalPersistence,
} from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { Crisp } from "crisp-sdk-web";

let firebaseApp;
Crisp.configure("b628bc87-f16d-42c4-912a-76d3f665027a", {
  autoload: false,
});
momentTz.tz.setDefault("America/Sao_Paulo");
moment.locale("pt-br");

export default function getFirebaseApp() {
  if (typeof window !== "undefined") {
    if (firebaseApp!!) return firebaseApp;
    const firebaseConfig = {
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: `${process.env.GATSBY_FIREBASE_PROJECT_ID}.firebaseapp.com`,
      databaseURL: `https://${process.env.GATSBY_FIREBASE_PROJECT_ID}.firebaseio.com`,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: `${process.env.GATSBY_FIREBASE_PROJECT_ID}.appspot.com`,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
      measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
    };

    firebaseApp = initializeApp(firebaseConfig);

    const db = initializeFirestore(firebaseApp, {
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    });

    setPersistence(getAuth(), indexedDBLocalPersistence);
    getPerformance();
    // connectFunctionsEmulator(getFunctions(), "localhost", 5001);

    return firebaseApp;
  }
  return null;
}
